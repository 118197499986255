h1, h2, h3, h4 {
  font-family: 'Arvo', serif;
  font-weight: 400;
  font-size: 2.8em;
  line-height: 1.5em;
  color: $grey-dark;
}

h2, h4 {
  font-size: 2.5em;
  color: $blue;
}

h4 {
  font-family: 'Droid Sans', sans-serif;
}

h5, h6 {
  font-family: 'Droid Sans', sans-serif;
  font-weight: 700;
  font-size: 2em;
  line-height: 1.5em;
  color: $grey;
}

p, span, dl, ul {
  font-family: 'Droid Sans', sans-serif;
  font-size: 1.3em;
  line-height: 1.8em;
  color: $grey;
}

a {
  text-decoration: underline;
  color: $grey;

  &:focus,
  &:hover {
    color: $blue;
  }
}