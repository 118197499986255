dl.progressbar {

  dd {
    border: 1px solid $blue;
    height: 10px;

    span {
      display: block;
      height: inherit;
      background-color: $blue;
      overflow: hidden;
    }
  }
}

.percent_10 {
  width: 10%;
}

.percent_20 {
  width: 20%;
}

.percent_30 {
  width: 30%;
}

.percent_40 {
  width: 40%;
}

.percent_50 {
  width: 50%;
}

.percent_60 {
  width: 60%;
}

.percent_70 {
  width: 70%;
}

.percent_80 {
  width: 80%;
}

.percent_90 {
  width: 90%;
}

.percent_95 {
  width: 95%;
}

.percent_100 {
  width: 100%;
}