@media print {

  html {
    font-size: 10px !important;
  }
  body, footer {
    background-color: white !important;
  }

  .print-inline {
    display: inline-block;
    margin-bottom: 0 !important;
    vertical-align: middle;
  }

  .container {
    box-shadow: none !important;
  }

  .site-header {
    padding-top: 0 !important;
    h1 {
      &:before,
      &:after {
        display: none !important;
      }
    }
  }

  .site-content {
    padding-top: 0 !important;
  }

  .row {
    padding: 0 0 2em !important;
    > header {
      float: none !important;
      padding: 0 0 2em !important;
      width: 100% !important;
      text-align: left !important;

      h3, h4 {
        display: inline-block !important;
        line-height: 1.8em;
      }
      h4 {

      }
      h4:before {
        content: "|";
        display: inline-block;
        margin: 0 2em;
      }
    }
    > div {
      padding-left: 0 !important;
      width: 65% !important;
      border: none !important;

      &.wide {
        float: none !important;
        width: 100% !important;
      }
    }
    aside {
      width: 35% !important;
    }
  }

  h2, h4, .company {
    color: #2f2f2f !important;
  }

  .circle, .inset {
    margin-top: 0 !important;
  }

  .inset {
    background-color: white !important;
    border: 1px solid black;
  }

  dl.progressbar {

    dd {
      border-color: #2f2f2f;

      span {
        background-color: #2f2f2f;
      }
    }
  }

  section.experience-item {
    display: inline-block;
    width: auto;
    padding-left: 110px !important;
    overflow: visible !important;

    h5 {
      display: inline-block;
      font-size: 2em !important;
    }

    .company {
      display: inline-block;
      margin-top: 0.25em;
      font-size: 1.4em !important;

      &:before {
        content: "|";
        display: inline-block;
        margin: 0 0.5em;
        font-size: 0.5em;
        line-height: 0.5em;
      }
    }

    .period {
      width: 80px !important;
      font-size: 1.2em !important;
      background-color: #2f2f2f !important;
    }

    li {
      float: left;
      margin-left: 1em !important;
    }

    + .experience-item {
      margin-top: 2em !important;
    }
  }

  #experience section.experience-item:nth-child(3) {
    page-break-after: always;
  }
}
