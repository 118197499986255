@charset "UTF-8";

@import "../bower_components/breakpoint-sass/stylesheets/breakpoint";
@import "../bower_components/normalize-css/normalize";

@import "fonts";
@import "vars";
@import "typo";
@import "progressbar";
@import "print";

///
// Some defaults
///
html {
  font-size: 12px;
}

body {
  font-size: 100%;
  line-height: 1.8em;
  background-color: hsl(197, 62%, 38%);
  background-image: repeating-linear-gradient(transparent, transparent 50px, rgba(0,0,0,.2) 50px, rgba(0,0,0,.2) 53px, transparent 53px, transparent 63px, rgba(0,0,0,.2) 63px, rgba(0,0,0,.2) 66px, transparent 66px, transparent 116px, rgba(0,0,0,.3) 116px, rgba(0,0,0,.3) 166px, rgba(255,255,255,.1) 166px, rgba(255,255,255,.1) 169px, rgba(0,0,0,.3) 169px, rgba(0,0,0,.3) 179px, rgba(255,255,255,.1) 179px, rgba(255,255,255,.1) 182px, rgba(0,0,0,.3) 182px, rgba(0,0,0,.3) 232px, transparent 232px),
  repeating-linear-gradient(270deg, transparent, transparent 50px, rgba(0,0,0,.2) 50px, rgba(0,0,0,.2) 53px, transparent 53px, transparent 63px, rgba(0,0,0,.2) 63px, rgba(0,0,0,.2) 66px, transparent 66px, transparent 116px, rgba(0,0,0,.3) 116px, rgba(0,0,0,.3) 166px, rgba(255,255,255,.1) 166px, rgba(255,255,255,.1) 169px, rgba(0,0,0,.3) 169px, rgba(0,0,0,.3) 179px, rgba(255,255,255,.1) 179px, rgba(255,255,255,.1) 182px, rgba(0,0,0,.3) 182px, rgba(0,0,0,.3) 232px, transparent 232px),
  repeating-linear-gradient(125deg, transparent, transparent 2px, rgba(0,0,0,.1) 2px, rgba(0,0,0,.1) 3px, transparent 3px, transparent 5px, rgba(0,0,0,.1) 5px);
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
}
h1, h2, h3, h4 {
  margin-bottom: 0;
}
h5, h6 {
  margin-bottom: 0.5em;
}

p {
  margin: 0 0 1em;
}

ul, li, dl, dt, dd {
  margin: 0;
  padding: 0;
}

dt, dd {
  display: inline-block;
  width: 100%;
  max-width: 250px;
}

dd {
  margin-bottom: 15px;
}

li {
  line-height: 1.6em;
  list-style: {
    type: square;
    position: inside;
  }
}

///
// Global wrappers
///
.container {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  overflow: hidden;
  background: white;
  background-image: repeating-linear-gradient(transparent, transparent 50px, rgba(0,0,0,.01) 50px, rgba(0,0,0,.01) 53px, transparent 53px, transparent 63px, rgba(0,0,0,.01) 63px, rgba(0,0,0,.01) 66px, transparent 66px, transparent 116px, rgba(0,0,0,.01) 116px, rgba(0,0,0,.01) 166px, rgba(255,255,255,.01) 166px, rgba(255,255,255,.01) 169px, rgba(0,0,0,.01) 169px, rgba(0,0,0,.01) 179px, rgba(255,255,255,.01) 179px, rgba(255,255,255,.01) 182px, rgba(0,0,0,.01) 182px, rgba(0,0,0,.01) 232px, transparent 232px),
  repeating-linear-gradient(270deg, transparent, transparent 50px, rgba(0,0,0,.01) 50px, rgba(0,0,0,.01) 53px, transparent 53px, transparent 63px, rgba(0,0,0,.01) 63px, rgba(0,0,0,.01) 66px, transparent 66px, transparent 116px, rgba(0,0,0,.01) 116px, rgba(0,0,0,.01) 166px, rgba(255,255,255,.01) 166px, rgba(255,255,255,.01) 169px, rgba(0,0,0,.01) 169px, rgba(0,0,0,.01) 179px, rgba(255,255,255,.01) 179px, rgba(255,255,255,.01) 182px, rgba(0,0,0,.01) 182px, rgba(0,0,0,.01) 232px, transparent 232px),
  repeating-linear-gradient(125deg, transparent, transparent 2px, rgba(0,0,0,.01) 2px, rgba(0,0,0,.01) 3px, transparent 3px, transparent 5px, rgba(0,0,0,.01) 5px);
}

.site-header {
  padding: 5em 0;
  position: relative;
  text-align: center;
  h1 {
    z-index: 2;
    font-size: 2em ;
    line-height: 0.8em;
    color: $grey-dark;
    text-transform: uppercase;

    @include breakpoint($screen-xs) {
      font-size: 2.3em;
    }
    @include breakpoint($screen-sm) {
      font-size: 3em;
    }
    @include breakpoint($screen-md) {
      font-size: 4em;
    }
  }

  h2 {
    font-size: 1.5em;
    margin: 1em 0;

    @include breakpoint($screen-sm) {
      font-size: 1.7em;
    }
    @include breakpoint($screen-md) {
      font-size: 2em;
    }
  }

  h3 {
    margin-bottom: 0;
    font-size: 1.3em;
    font-weight: 400;
    line-height: 1.2em;
    color: $grey;

    @include breakpoint($screen-sm) {
      font-size: 1.5em;
    }
    @include breakpoint($screen-md) {
      font-size: 1.7em;
    }
  }
}

.site-content {
  padding: 40px 0;
}

.site-footer {
  border-top: 1px solid $grey-lighter;
  padding: 10px 0;
  text-align: center;
  background-color: #f6f6f6;

  p {
    margin-bottom: 0
  }
}

///
// Sections.
///
.row {
  display: inline-block;
  width: 100%;
  padding: 2em;
  box-sizing: border-box;

  > header {
    margin-top: 2em;
    text-align: center;
    box-sizing: border-box;

    h4 {
      font-size: 1.35em;
      text-transform: lowercase;
    }

    @include breakpoint($screen-md) {
      float: left;
      width: 25%;
      margin-top: 0;
      padding-right: 2em;
      text-align: right;
    }
  }

  > div {
    margin-top: 45px;
    box-sizing: border-box;

    @include breakpoint($screen-md) {
      float: left;
      width: 41%;
      margin-top: 0;
      padding-left: 2em;

      &.wide {
        width: 75%;
      }
    }
  }
  aside {
    margin-top: 2em;
    text-align: center;
    box-sizing: border-box;

    h5 {
      font-size: 1.5em;
      margin-bottom: 2em;
    }

    @include breakpoint($screen-md) {
      float: left;
      margin-top: 0;
      width: 34%;
      padding-left: 2em;
    }
  }
}

///
// About-contact
///
.circle {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 250px;
  height: auto;
  border-radius: 50%;
  border: 3px solid white;
  box-shadow: 1px 2px 10px 1px rgba(3,3,3,.15);
  overflow: hidden;
}

.contact {
  margin-top: 2em;

  .fa {
    font-size: 2em;
  }

  a {
    display: inline-block;
    transition: all .2s ease-in-out;

    & + a {
      margin-left: 15px;
    }

    &:hover, &:focus {
      transform: scale(1.1);
    }
  }
}

///
// Expertise-skills
///
.inset {
  margin: 2em;
  padding: 2em;
  background-color: $grey-dark;

  dl, h5 {
    color: white;
  }
}

///
// Experience item
///
section.experience-item {
  position: relative;
  padding-left: 170px;

  header {
    margin-bottom: 1em;
  }

  h5 {
    font-family: 'Arvo', serif;
    font-size: 2.25em;
    font-weight: 400;
    line-height: 1em;
    margin-bottom: 0;
  }
  .company {
    font-family: 'Droid Sans', sans-serif;
    font-size: 1.8em;
    color: $blue;
  }
  h6 {
    font-size: 1.4em;
  }

  a {
    text-transform: lowercase;
  }

  .period {
    position: absolute;
    top: 0;
    left: 0;
    padding: 5px;
    width: 130px;
    font-size: 1.1em;
    line-height: 1.25em;
    color: white;
    text-align: center;
    background-color: $blue;

    @include breakpoint($screen-sm) {
      font-size: 1.4em;
    }
    @include breakpoint($screen-md) {
      font-size: 1.6em;
    }
  }

  + .experience-item {
    margin-top: 4em;
  }
}
